import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ContentLoader from '@jumbo/components/ContentLoader';
import { isUndefined, isNull, result } from 'lodash';
import { localStorage_getItem } from '../common/LocalStorage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { history } from 'redux/store';

const MySwal = withReactContent(Swal);

const RestrictedRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);
  // const history = useHistory();
  let { modules } = useSelector(state => state.auth);
  let access_obj = modules.reduce((prev, curr) => {
    prev[curr.link] = curr.name;
    return prev;
  }, {});

  let permissions = {
    view: false,
    edit: false,
    add: false,
    delete: false,
  };
  let has_access = false;
  let all_access_list = [];
  if (localStorage_getItem('user_type') == '1') {
    has_access = true;

    permissions = {
      view: true,
      edit: true,
      add: true,
      delete: true,
    };
  } else {
    let access_list = localStorage_getItem('access_list');

    if (!isNull(access_list)) {
      if (isUndefined(access_list['view_page_level_access'])) {
        access_list['view_page_level_access'] = [];
      }
      all_access_list = [...access_list['view_page_level_access']];

      if (all_access_list.includes(access_obj[rest.path])) {
        has_access = true;
        permissions = {
          view: access_list['view_page_level_access']?.includes(access_obj[rest.path]),
          edit: access_list['edit_page_level_access']?.includes(access_obj[rest.path]),
          add: access_list['add_page_level_access']?.includes(access_obj[rest.path]),
          delete: access_list['delete_page_level_access']?.includes(access_obj[rest.path]),
        };
      }
    }
  }

  const showAccessDeniedMsg = () => {
    MySwal.fire({
      title: "User doesn't have access to this page",
      icon: 'error',
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
      confirmButtonAriaLabel: 'Ok!',
    }).then(result => {
      history.goBack();
    });
  };

  const isAuthenticated = () => {
    if (typeof window == 'undefined') return false;
    if (localStorage.getItem('token')) return true;
    else return false;
  };

  return (
    // <Route
    //   {...rest}
    //   render={props =>
    //     authUser ? (
    //       <Component {...props} />
    //     ) : (
    //       <Redirect
    //         to={{
    //           pathname: '/signin',
    //           state: { from: props.location },
    //         }}
    //       />
    //     )
    //   }
    // />

    <Route
      {...rest}
      render={props =>
        has_access ? (
          isAuthenticated() ? (
            <Component permissions={permissions} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          showAccessDeniedMsg()
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser, modules } = useSelector(({ auth }) => auth);
  const location = useLocation();
  let page_to_link = modules.reduce((prev, curr) => {
    prev[curr.name] = curr.link;
    return prev;
  }, {});

  let freeURLs = ['/signin', '/forget-password', '/password-recovery'];

  if (!authUser && !freeURLs.includes(location.pathname)) {
    return <Redirect to={'/signin'} />;
  } else if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (authUser && (location.pathname === '/signin' || location.pathname === '/login')) {
    if (localStorage_getItem('user_type') == '1') {
      return <Redirect to={'/user-management'} />;  
    } else {
        let access_list = localStorage_getItem('access_list');
        let view_page = access_list['view_page_level_access'][0];
        let link = page_to_link[view_page];
        return <Redirect to={link} />;
    }
  }
  if(authUser && localStorage.getItem('password_expired') == 'true' && location.pathname != '/change-password'){
    return <Redirect to={'/change-password?exp=1'} />;
  }

  return (
    <React.Fragment>
      <Suspense fallback={<ContentLoader />}>
        <Switch>
          {/* <RestrictedRoute path="/" exact={true} component={lazy(() => import('./Pages/Dashboard'))} /> */}
          {/* <RestrictedRoute path="/dayahead-forecast" exact={true} component={lazy(() => import('./Pages/DayaheadDemand'))} /> */}
          {/* <RestrictedRoute path="/upload-data" component={lazy(() => import('./Pages/UploadPages'))} /> */}
          {/* <RestrictedRoute path="/availability" component={lazy(() => import('./Pages/Availability'))} />
          <RestrictedRoute path="/weather" component={lazy(() => import('./Pages/Weather'))} />
          <RestrictedRoute path="/availability-forecast" component={lazy(() => import('./Pages/AvailabilityForecast'))} /> */}
          <Route path="/signin" component={lazy(() => import('./Auth/Login'))} />

          {/* <Route path="/signup" component={lazy(() => import('./Auth/Register'))} /> */}
          {/* <Route path="/forgot-password" component={lazy(() => import('./Auth/ForgotPassword'))} /> */}
          <RestrictedRoute path="/change-password" component={lazy(() => import('./Auth/ChangePassword'))} />
          <RestrictedRoute path="/upload-data" component={lazy(() => import('./Pages/UploadPages'))} />

          {/* <RestrictedRoute path="/download-data" component={lazy(() => import('./Pages/DownloadPages'))} /> */}
          <RestrictedRoute path="/company-management" component={lazy(() => import('./Pages/CompanyManagement'))} />
          <RestrictedRoute path="/group-management" component={lazy(() => import('./Pages/GroupManagement'))} />
          <RestrictedRoute path="/user-management" component={lazy(() => import('./Pages/UserManagement/index'))} />
          <RestrictedRoute path="/module-management" component={lazy(() => import('./Pages/ModuleManagement/index'))} />
          <RestrictedRoute path="/plant-management" component={lazy(() => import('./Pages/Plants/index'))} />
          <RestrictedRoute path="/plant-details" component={lazy(() => import('./Pages/PlantDetails'))} />
          <RestrictedRoute path="/forecast-analysis" component={lazy(() => import('./Pages/ForecastAnalysis'))} />
          <RestrictedRoute path="/auto-scheduling" component={lazy(() => import('./Pages/AutoScheduling'))} />
          <RestrictedRoute path="/plant-portfolio" component={lazy(() => import('./Pages/PlantPortfolio'))} />
          <RestrictedRoute path="/published-data" component={lazy(() => import('./Pages/PublishData/index'))} />
          <RestrictedRoute path="/reports" component={lazy(() => import('./Pages/Reports/Reports'))} />
          <RestrictedRoute path="/notification-alert" component={lazy(() => import('./Pages/Alert'))} />
          <RestrictedRoute path="/home" component={lazy(() => import('./Pages/Dashboard'))} />
          <RestrictedRoute path="/logs" component={lazy(() => import('./Pages/Logs'))} />
          <RestrictedRoute path="/peak-power" component={lazy(() => import('./Pages/PeakPower'))} />
          <RestrictedRoute path="/peakpower-upload" component={lazy(() => import('./Pages/ForecastUpload'))} />
          <Route component={lazy(() => import('./Pages/404'))} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
